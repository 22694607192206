import { gql } from '@/utils/graphql';
import { cardCalendarFragment } from './card-calendar';
import { cardShowFragment } from './card-show';

export const archiveFragment = gql`
	fragment archiveFragment on ArchiveFieldsType {
		filters {
			seasons(first: 999) {
				nodes {
					id: databaseId
					name
					count
					isCurrent
					isSelected
					uri
				}
			}
		}
		events {
			nodes {
				...cardShowFragment
			}
		}
		eventDates {
			...cardCalendarFragment
		}
	}
	${cardShowFragment}
	${cardCalendarFragment}
`;
