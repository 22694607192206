import { gql } from '@/utils/graphql';

import languageFields from './language';
import translationsFields from './translations';
import contentSeoFields from './content-seo';
import alertFields from './alert';
import editPreviewFields from './edit-preview-fields';

export const eventFragment = gql`
fragment eventFragment on Event {
	blocksJSON
	uri
	${editPreviewFields}
	${alertFields}
	${languageFields}
	${translationsFields}
	${contentSeoFields}
}
`;
