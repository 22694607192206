import { gql } from '@/utils/graphql';

import languageFields from './language';
import translationsFields from './translations';
import contentSeoFields from './content-seo';
import alertFields from './alert';
import editPreviewFields from './edit-preview-fields';

export const productionFragment = gql`
fragment productionFragment on Production {
	blocksJSON
	uri

	${alertFields}
	${editPreviewFields}
	${languageFields}
	${translationsFields}
	${contentSeoFields}
}
`;
