const contentSeoFields = `
seo {
	title
	metaDesc
	metaKeywords
	metaRobotsNoindex
	metaRobotsNofollow
	canonical
	schema {
		raw
	}
	opengraphTitle
	opengraphDescription
	opengraphUrl
	opengraphImage {
		relativeUrl
	}
	twitterTitle
	twitterDescription
	twitterImage {
		relativeUrl
	}
	breadcrumbs {
		text
		url
	}
}
`;

export default contentSeoFields;
