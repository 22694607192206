const alertFields = `
alert {
	enabled
	text
	type
	cta {
		url
		label
	}
}`;

export default alertFields;
