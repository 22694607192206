import { gql } from '@/utils/graphql';

export const cardShowFragment = gql`
	fragment cardShowFragment on Event {
		id: databaseId
		uptitle
		title(format: RENDERED)
		startDate
		endDate
		datesString
		eventCustomFields {
			specialMention
			isEvent
			isAroundTheShow
			hasDetailPage
			ticketLink
			ticketButtonText
			hasDetailPage
			production: relatedProductions {
				__typename
			}
			datesEvent {
				date
				ticketLink
				ticketButtonText
			}
			publics: publicCardShow {
				nodes {
					... on Public {
						id: databaseId
						title: name
						icon: publicCustomFields {
							img: icon {
								node {
									src: relativeUrl
									mediaDetails {
										height
										width
									}
									altText
								}
							}
						}
					}
				}
			}
		}
		link
		image: featuredImage {
			node {
				altText
				relativeUrl
				mediaDetails {
					width
					height
				}
			}
		}
		excerpt(format: RENDERED)
		locations {
			nodes {
				name
			}
		}
		genres {
			nodes {
				id: slug
				name
			}
		}
		publics {
			nodes {
				id: slug
				name
			}
		}
		thematics {
			nodes {
				id: slug
				name
				description
				acf {
					link
				}
			}
		}
		seasons {
			nodes {
				id: databaseId
				name
			}
		}
	}
`;
