import configs from '@/configs.json';
import { fetchAPI } from '@/lib/api';
import { gql } from '@/utils/graphql';
// import { getUriWithoutPagination } from '@/utils/pagination';

import { pageFragment } from './fragments/page';
import { productionFragment } from './fragments/production';
import { eventFragment } from './fragments/event';
import { artistFragment } from './fragments/artist';
import { archiveFragment } from './fragments/archive';

/**
 * NOTE: in preview, the `uri` could be in fact the ID (i.e. a draft doesn't have a slug/uri yet)
 *
 * @param {string}      uri
 * @param {boolean}     preview
 * @param {object}      auth
 * @param {null|string} lang
 *
 * @returns
 */
export default async function getNodeByURI(
	uri,
	preview,
	auth,
	lang = null,
	previewDraft = false
) {
	// uri = getUriWithoutPagination(uri); // Removes '/page/...' from uri if needed

	// The slug may be the id of an unpublished post
	const [match, id] = uri.match(/^(?:\/?\w{2})?\/(\d+)\/?/) || [];
	const isId = !!match;

	const variables: any = {
		isPreview: preview,
		isPreviewDraft: previewDraft,
		lang,
		language: lang,
	};

	if (isId) variables.id = Number.parseInt(id);
	else variables.uri = uri;

	const query = isId ? nodeByIdQuery : nodeByUriQuery;
	let { node, seo, generalSettings, readingSettings } = await fetchAPI(
		query,
		{
			variables,
			auth,
		}
	);

	if (node && configs.isMultilang) {
		const { __typename, translation } = node;
		node = { __typename, ...translation };
	}

	if (node && previewDraft) {
		node.blocksJSON = node?.preview?.node?.blocksJSON ?? '[]';
	}

	return !node
		? null
		: {
				...node,
				siteSEO: seo,
				siteSettings: { ...generalSettings, ...readingSettings },
		  };
}

const nodeByUriQuery = gql`
	query nodeByUriQuery(
		$uri: String!
		$lang: LanguageCodeFilterEnum!
		$language: LanguageCodeEnum!
		$isPreview: Boolean = false
		$isPreviewDraft: Boolean = false
	) {
		node: nodeByUri(uri: $uri) {
			__typename
			... on Page {
				translation(language: $language) {
					...pageFragment
					archive {
						...archiveFragment
					}
				}
			}
			... on Event {
				translation(language: $language) {
					...eventFragment
				}
			}
			... on Production {
				translation(language: $language) {
					...productionFragment
				}
			}
			... on Artist {
				translation(language: $language) {
					...artistFragment
				}
			}
		}
		generalSettings {
			title
		}
		readingSettings {
			pageForThematics(lang: $lang) {
				uri
			}
		}
		seo {
			schema {
				siteName
				siteUrl
				companyName
			}
			social {
				twitter {
					username
					cardType
				}
			}
			openGraph {
				defaultImage {
					relativeUrl
				}
			}
		}
	}
	${pageFragment}
	${eventFragment}
	${productionFragment}
	${artistFragment}
	${archiveFragment}
`;

const nodeByIdQuery = gql`
	query nodeByIdQuery(
		$id: ID!
		$lang: LanguageCodeFilterEnum!
		$language: LanguageCodeEnum!
		$isPreview: Boolean = false
		$isPreviewDraft: Boolean = false
	) {
		node(id: $id, idType: DATABASE_ID) {
			__typename

			... on Page {
				translation(language: $language) {
					...pageFragment
					archive {
						...archiveFragment
					}
				}
			}
			... on Event {
				translation(language: $language) {
					...eventFragment
				}
			}
			... on Production {
				translation(language: $language) {
					...productionFragment
				}
			}
			... on Artist {
				translation(language: $language) {
					...artistFragment
				}
			}
		}
		generalSettings {
			title
		}
		readingSettings {
			pageForThematics(lang: $lang) {
				uri
			}
		}
		seo {
			schema {
				siteName
				siteUrl
				companyName
			}
			social {
				twitter {
					username
					cardType
				}
			}
			openGraph {
				defaultImage {
					relativeUrl
				}
			}
		}
	}
	${pageFragment}
	${eventFragment}
	${productionFragment}
	${artistFragment}
	${archiveFragment}
`;
