import { gql } from '@/utils/graphql';

import languageFields from './language';
import translationsFields from './translations';
import contentSeoFields from './content-seo';
import alertFields from './alert';
import editPreviewFields from './edit-preview-fields';

export const artistFragment = gql`
fragment artistFragment on Artist {
	id: databaseId
	title(format: RENDERED)
	blocksJSON
	uri
	${alertFields}
	artistCategories {
		nodes {
			name
		}
	}
	featuredImage {
		node {
			altText
			caption
			relativeUrl
			mediaDetails {
				width
				height
			}
		}
	}
	${alertFields}
	${editPreviewFields}
	${languageFields}
	${translationsFields}
	${contentSeoFields}
}
`;
