import { DESIGNER_CAPABILITY } from '@superhuit/starterpack-customizer';

/**
 * Can current logged-in user update the Block theme ?
 *
 * @param {string} wpRestApiEndpoint WordPress API endpoint url
 * @param {*string} nonce X-WP-Nonce needed for request authentification
 * @param {AbortSignal|null} signal An abort signal to abort request
 * @returns {Promise<boolean>}
 */
export default async function getCanUserUpdateBlockTheme(
	wpRestApiEndpoint,
	nonce,
	signal = null
) {
	return fetch(`${wpRestApiEndpoint}/wp/v2/users/me?context=edit`, {
		signal,
		credentials: 'include',
		headers: { 'X-WP-Nonce': nonce },
	})
		.then((res) => res.json())
		.then(({ capabilities }) =>
			Object.keys(capabilities).includes(DESIGNER_CAPABILITY)
		)
		.catch((err) => {
			if (err.name !== 'AbortError')
				console.warn('User capabilities could not be retrieved', err);
			return false;
		});
}
