import { gql } from '@/utils/graphql';

import languageFields from './language';
import translationsFields from './translations';
import contentSeoFields from './content-seo';
import alertFields from './alert';
import editPreviewFields from './edit-preview-fields';

export const pageFragment = gql`
fragment pageFragment on Page {
	template {
		templateFilename
	}
	title(format: RENDERED)
	blocksJSON
	uri
	isFrontPage
	isCalendarPage
	isEventsPage
	isSeasonPage
	${editPreviewFields}
	${alertFields}
	${languageFields}
	${translationsFields}
	${contentSeoFields}
	seasonCTA {
		title
		href
		type
	}
	seasonPageCTAs {
		title
		href
		type
	}
}
`;
