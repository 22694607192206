const editPreviewFields = `
editLink @include(if: $isPreview)
preview @include(if: $isPreviewDraft) {
	node {
		blocksJSON
	}
}
`;

export default editPreviewFields;
